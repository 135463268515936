<template>
<main id="main" class="site-main site-main-detail">
    <div class="postercard">
        <div class="postercard__minipic">
            <img :src="urlPath+'uploads/media/blog/blog-coverimage.jpg'" alt="Nepal Alternative Blog Image">
            <!-- <img src="uploads/img32.jpg" width="1400" height="448" alt="image description"> -->
        </div>
    </div>
    <section class="blog">
        <div class="container">
            <div class="row">
                <div class="col xs-12 sm-8 md-9">
                    <div class="breadcrumb">
                        <ul>
                            <li><router-link :to="{name:'Home'}">Home</router-link></li>
                            <li><span class="live">Search</span></li>
                        </ul>
                    </div>
                    <h1 class="folio__title">Search Result</h1>
                    <template  v-for="(combopackage,index) in searchresults" :key="index">
                    <div class="blog__item" v-if="index<1">
                        <router-link :to="{name:'Packagedetail', params:{slug:combopackage.slug,title:combopackage.seo_title,keyword:combopackage.seo_keyword,description:combopackage.seo_description}}"><img :src="urlPath+'uploads/media/package/thumbnail/326245_'+combopackage.featuredimage" alt="image description" class="blog__item-img" width="892" height="414"></router-link>
                        <div class="blog__item-group">
                            <h2 class="blog__item-title">
                                <router-link :to="{name:'Packagedetail', params:{slug:combopackage.slug,title:combopackage.title}}" >{{combopackage.title}}</router-link>
                                </h2>
                            <p>{{truncateText(combopackage.excerpt)}}</p>
                        </div>
                    </div>
                    </template>
                    <div class="row">
                        <template  v-for="(blog,index) in searchresults" :key="index">
                        <div class="col xs-12 sm-6" v-if="index>=1">
                            <div class="blog__item">
                                <router-link :to="{name:'Packagedetail', params:{slug:blog.slug,title:blog.seo_title,keyword:blog.seo_keyword,description:blog.seo_description}}"><img :src="urlPath+'uploads/media/package/thumbnail/326245_'+blog.featuredimage" alt="image description" class="blog__item-img" width="434" height="232"></router-link>    
                                <!-- <router-link :to="{name:'Packagedetail',params:{slug:blog.slug}}" class="blog__item-img">
                                    <img :src="urlPath+'uploads/media/blog/thumbnail/440230_'+blog.image" alt="blog.title" width="434" height="232">
                                </router-link> -->
                                <h2 class="blog__item-title"><router-link :to="{name:'Packagedetail',params:{slug:blog.slug}}">{{blog.title}}</router-link></h2>
                            </div>
                        </div>
                        </template>
                        
                    </div>
                </div>
                <div class="col xs-12 sm-4 md-3">
                    
                    <div class="related">
                        <h2 class="related__title"><strong>Awesome</strong> Package</h2>
                        <featured />
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
</template>

<script>
import axios from 'axios';
import Awesomepackage from "./Innercomponent/Awesomepackageinner.vue";
export default {
    name : 'Search',
    props: ['searchkey'],
    data() {
        return {
            searchresults:'',
            newsearchkeyword: undefined,
            apiPath:process.env.VUE_APP_API,
            urlPath:process.env.VUE_APP_PATH,
        }
    },
    async mounted() {
        this.searchpackage(this.searchkey);
    },
    methods: {
        searchpackage(searchkeyword){
            axios.get(this.apiPath+'search/'+searchkeyword)
                .then((response) => {
                    this.searchresults = response.data;
                })
        },
        truncateText(value, length = 80) {
            return value.length <= length ?
                value : value.split(' ').slice(0, 30).join(" ") + "..."
        }
    },
    components: {
        'featured': Awesomepackage,
    },
    watch:{
        searchkey(){
            this.newsearchkeyword = this.$route.params.searchkey;
            this.searchpackage(this.newsearchkeyword);
        }
    }

}
</script>
